import React from 'react'
import "../output.css"

import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import EuroIcon from '@mui/icons-material/Euro'
import EvStationIcon from '@mui/icons-material/EvStation'
import Typography from '@mui/material/Typography'

const Activity = ({ activity }) => {
  console.log("activity")
  console.log(activity)
  if (activity.chargepoint_description !== undefined) {
    // it's a session
    return (
      <Session session={activity} />
    )
  }
  
  // it's a deposit
  return (
    <Deposit amount={activity.amount} occuredAt={activity.occured_at} />
  )

  /*
  return (
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Brunch this weekend?"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                Ali Connors
              </Typography>
              {" — I'll be in your neighborhood doing errands this…"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Summer BBQ"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                to Scott, Alex, Jennifer
              </Typography>
              {" — Wish I could come, but I'm out of town this…"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Oui Oui"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                Sandra Adams
              </Typography>
              {' — Do you have Paris recommendations? Have you ever…'}
            </React.Fragment>
          }
        />
      </ListItem>
  )
  */
}

const Deposit = ({ amount, occuredAt }) => {
  return (
    <ListItem>
      <ListItemIcon fontSize="large">
        <EuroIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
          component="span"
          variant="body2"
          sx={{ color: 'green', display: 'inline', whiteSpace: "pre-wrap" }}
          >
            {`+ ${format(amount / 100, 2)}€`}
          </Typography>
        }
        secondary={
          <React.Fragment>
            {occuredAt.toLocaleString()}
          </React.Fragment>
        }
      />
    </ListItem>
  )
}

/*
 kWh verbruikt
 charger description
  start time
  duration
  price per kWh
  Total price (- XX,YY€)
 */
const Session = ({ session }) => {
  console.log(session.occured_at)
  return (
    // <ListItem alignItems="flex-start">
    <ListItem>
      <ListItemIcon>
        <EvStationIcon fontSize="large" />
      </ListItemIcon>
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              sx={{ color: 'red', display: 'inline', whiteSpace: "pre-wrap" }}
            >
              {`- ${format(session.total_price_eurocents / 100, 2)}€`}
            </Typography>
            <Typography
              component="span"
              variant="body2"
              sx={{ display: 'inline', whiteSpace: "pre-wrap" }}
            >
              {` (${(session.total_energy_wh / 1000).toFixed(2)} kWh x ${format(session.price_per_kwh / 100, 2)}€/kWh)`}
            </Typography>
          </React.Fragment>
        }
        secondary={
            <Typography
              component="span"
              variant="body2"
              sx={{ color: 'text.secondary', display: 'inline', whiteSpace: "pre-wrap" }}
            >
              {
                `${session.chargepoint_description}\n${session.occured_at.toLocaleString()}`
              }
            </Typography>
        }
      />
    </ListItem>
  )
}

function format(amount, decimals = 2) {
  /* 
     Taken from https://stackoverflow.com/a/14428340/2828147
       1        --> "1.00"
       12       --> "12.00"
       123      --> "123.00"
       1234     --> "1,234.00"
       12345    --> "12,345.00"
       123456   --> "123,456.00"
       1234567  --> "1,234,567.00"
       12345.67 --> "12,345.67"
  */
  return amount.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export default Activity
