//export const ocppEndpoint = 'https://autorize.eu:2096'
export const endpoint = 'https://autorize.eu:2053'
//export const endpoint = 'https://localhost:2053'

export async function startTx(chargerId, connectorId, cardId, token) { //: Promise<void>
  const response = await fetch(`${endpoint}/charger/${chargerId}/${connectorId}/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      cardId,
    }),
  })

  if (response.status !== 200) {
    const data = await response.json()
    throw new Error(data.reason)
  }
}

export async function startBudgetTx(chargerId, connectorId, budget, token) { //: Promise<void>
  const response = await fetch(`${endpoint}/charger/${chargerId}/${connectorId}/startWithBudget`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      budget,
    }),
  })

  if (response.status !== 200) {
    const data = await response.json()
    throw new Error(data.reason)
  }
}

export async function authorizeCard(card, token) {
  const cardId = card.id
  console.log("Authorize request")
  const response = await fetch(`${endpoint}/card/authorize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      cardId
    })
  })

  return await response.json()
}

export async function getCards(token) {
  console.log("Fetching: " + `${endpoint}/cards`)
  console.log("token: " + token)
  // https://cors-anywhere.herokuapp.com/
  const response = await fetch(`${endpoint}/cards`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    const data = await response.json()
    return {
      success: true,
      cards: data.cards,
    }
  }
  else {
    return {
      success: false,
      response,
    }
  }
}

export async function getActivity(token) {
  console.log("Fetching: " + `${endpoint}/activity`)
  console.log("token: " + token)
  const response = await fetch(`${endpoint}/activity`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    const data = await response.json()

    const activity = data.sessions.concat(data.deposits)
      .sort((a, b) => b.occured_at - a.occured_at) // sort in descending order (newest first)
      .map((item) => {
        return {
          ...item,
          occured_at: new Date(item.occured_at)
        }
      })

    return {
      success: true,
      activity: activity,
    }
  }
  else {
    return {
      success: false,
      response,
    }
  }
}

export async function getBalance(token) {
  const response = await fetch(`${endpoint}/account`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    const data = await response.json()
    return {
      success: true,
      balance: data.balance / 100, // balance in euro
    }
  }

  return {
    success: false,
    response
  }
}

export async function getChargerInfo(charger, token) {
  const response = await fetch(`${endpoint}/charger/${charger}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    const data = await response.json()
    return {
      success: true,
      chargerInfo: data,
    }
  }

  return {
    success: false,
    response
  }
}

export async function checkout(amountInEuro, token) {
  const response = await fetch(`${endpoint}/deposit`, {
    method: 'POST',
    redirect: 'follow', // follow Stripe's redirect
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      amount: amountInEuro * 100, // amount in eurocents
    })
  })

  const body = await response.json()
  window.location.href = body.url
}