import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import MoneyIcon from '@mui/icons-material/Money'
import EvStationIcon from '@mui/icons-material/EvStation'
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { Alert } from "reactstrap"

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import Header from "../components/Header";
import NavBar from "../components/NavBar"
import Loading from "../components/Loading";

import "../output.css";

import { startBudgetTx, getChargerInfo } from "../utils/authorizeCard"

// Autorize API runs on port 8444 (internally) - 2053 (externally)
// OCPP proxy ws server runs on port 8445 (internally) - 2096 (externally)
// This website runs on port 8443 (internally) - 443 (externally)

const ChargeWithBudget = () => {
  const {
    error: authError,
    loginWithPopup,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout,
  } = useAuth0()

  const { charger } = useParams()

  const [authenticationError, setAuthenticationError] = useState(undefined)

  const [state, setState] = useState({
    //error: null,
    apiMessage: null, // null | { color: 'success' | 'warning', msg: string }
  })

  const [budget, setBudget] = useState(50) // default budget
  const [connector, setConnector] = useState()
  const [chargerInfo, setChargerInfo] = useState()

  const fetchChargerInfo = async () => {
    const token = await getAccessToken()
    const res = await getChargerInfo(charger, token)

    if (res.success) {
      setChargerInfo(res.chargerInfo)
    }
  }

  useEffect(fetchChargerInfo, [])

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently(
      )
    } catch (error) {
      setAuthenticationError(error)
      return undefined
    }
  }

  if (authError !== undefined || authenticationError !== undefined) {
    console.log("Authentication error: " + authError ?? authenticationError)
    console.log("Redirecting to home page")

    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }

  }

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup()
      setState({
        ...state,
        error: null,
      })
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      })
    }
  }

  const startSession = async () => {
    try {
      const token = await getAccessToken()
      if (token === undefined) return // failed to fetch access token
      
      // Now try to start the session
      console.log("Contacting charger...")
      setState({
        ...state,
        apiMessage: { color: 'blue', msg: 'Contacting charger...' }
      })

      const budgetInCents = budget * 100 // convert to cents
      await startBudgetTx(charger, connector, budgetInCents, token)

      console.log("Session started")
      setState({
        ...state,
        apiMessage: { color: 'green', msg: 'Session started. Your car should start charging soon.' }
      })
    } catch (error) {
      setState({
        ...state,
        apiMessage: { color: 'warning', msg: 'Failed to start session: ' + error.message }
      })
    }
  }

  const handle = (e, fn) => {
    e.preventDefault()
    fn()
  }

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({
      ...state,
      apiMessage: undefined,
    })
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <div className="bg-light-gray h-screen">
      {state.error === "consent_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleConsent)}
          >
            consent to get access to users api
          </a>
        </Alert>
      )}

      {state.error === "login_required" && (
        <Alert color="warning">
          You need to{" "}
          <a
            href="#/"
            class="alert-link"
            onClick={(e) => handle(e, handleLoginAgain)}
          >
            log in again
          </a>
        </Alert>
      )}

      <Snackbar
        autoHideDuration={6000}
        open={!!state.apiMessage}
        onClose={handleClose}
        message={state.apiMessage?.msg}
        color={state.apiMessage?.color}
        action={action}
      />

      <Header title='Charge' />
      
      {chargerInfo === undefined && <Loading />}
      
      {
        chargerInfo !== undefined &&
        <div className="flex flex-col items-center px-4 py-6">
          <Box sx={{ 
              backgroundColor: 'white', 
              borderRadius: '8px', 
              padding: '20px', 
              marginBottom: '24px',
              width: '100%',
              maxWidth: '400px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
              mb: 2
            }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              {chargerInfo.description}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.75 }}>
              <Box sx={{ display: 'flex', justifyContent: 'start', mr: 0.5 }}>
                <MoneyIcon sx={{mr: 1}} />
                <Typography>
                  {format(chargerInfo.pricePerKwh / 100)}€/kWh
                </Typography>
              </Box>
                
              <Box sx={{ display: 'flex', justifyContent: 'start', mr: 0.5 }}>
                <EvStationIcon sx={{mr: 1}} />
                <Typography>
                  {chargerInfo.maxPower.toFixed(1)} kW
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ 
              color: chargerInfo.status === 'CONNECTED' ? 'success.main' : 'error.main',
              mb: 2
            }}>
              Status: {chargerInfo.status === 'CONNECTED' ? 'Online' : 'Offline'}
            </Typography>
          
            {
              chargerInfo.status === 'CONNECTED' &&
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 2,
                mb: 2
              }}>
                <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
                  Choose a connector
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  {chargerInfo.connectors.map((conn) => (
                    <Button
                      key={conn.id}
                      variant={conn.id === connector ? "contained" : "outlined"}
                      sx={{ width: '20%', mr: 2 }}
                      onClick={() => {
                        setConnector(conn.id);
                      }}
                      disabled={!conn.available}
                    >
                      {conn.id}
                    </Button>
                  ))}
                </Box>
                
                <Typography sx={{ fontSize: 'lg', fontWeight: 'lg' }} align="center">
                  Set a maximum budget for your session
                </Typography>
                <NumberInput
                  aria-label="Maximum budget input"
                  min={1}
                  value={budget}
                  onChange={(_event, val) => setBudget(val)}
                />
                <Button
                  className=""
                  variant="outlined"
                  onClick={startSession}
                  disabled={connector === undefined}
                  startIcon={<ElectricBoltIcon />}
                >
                  Charge
                </Button>
              </Box>
            }
          </Box>
        </div>
      }

      <NavBar tab='Charger' />
    </div>
  )
}

// TODO: set max budget to available saldo (and maybe even hide it in the UI) (can also be done on the server)
//       or hide it under advanced options
//       --> need to disable start button and display a warning if available saldo is too small

export default withAuthenticationRequired(ChargeWithBudget, {
  onRedirecting: () => <Loading />,
})

// TODO: move this to an util file (see duplicate function in Activity.js component and Account.js component)
function format(amount, decimals = 2) {
  /* 
     Taken from https://stackoverflow.com/a/14428340/2828147
       1        --> "1.00"
       12       --> "12.00"
       123      --> "123.00"
       1234     --> "1,234.00"
       12345    --> "12,345.00"
       123456   --> "123,456.00"
       1234567  --> "1,234,567.00"
       12345.67 --> "12,345.67"
  */
  return amount.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}