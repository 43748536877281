import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/base/Button';

const tabs = [ 'Charger', 'Activity', 'Account']

export default function NavBar({ tab }) {
  const [value, setValue] = React.useState(tabs.indexOf(tab));

  return (
    <Box sx={{ width: 1, pb: 4 }}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(_event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={Button} href="/budget/1" label="Charge" icon={<ElectricBoltIcon />} />
          <BottomNavigationAction component={Button} href="/activity" label="Activity" icon={<AssignmentIcon />} />
          <BottomNavigationAction component={Button} href="/account" label="Account" icon={<AccountBoxIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
